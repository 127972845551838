import React from "react";

import logo from "../../assets/images/footer/logo.png";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="footer-container">
      <div className="footer-availability">Available in</div>
      <div className="footer-availability-location">California</div>
      <div className="footer-availability-location">Arizona</div>
      <div className="footer-availability-location">Nevada</div>
      <div className="footer-logo">
        <img
          src={logo}
          alt="Logo"
          style={{ width: "100%", maxWidth: "500px" }}
        />
      </div>
      <div className="footer-address">
        1950 East Maule, Las Vegas, NV 89119 | stephen@ennion.com
      </div>
    </div>
  );
};
