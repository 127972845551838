import React from "react";

interface AboutUsContainerProps {}

export const AboutUsContainer: React.FC<AboutUsContainerProps> = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content-container">
        <div className="about-us-hero about-us-hero-doors">
          <div className="about-us-hero-left">
            <div className="about-us-hero-left-text">
              A window into how the ultimate glass fabrication and bi-fold and
              sliding door company was born.
            </div>
          </div>
        </div>
        <div className="about-us-section">
          <div className="about-us-section-text">
            <div className="about-us-section-text-title">
              We eat, sleep and dream glass.
            </div>
            <div className="about-us-section-text-content">
              <p>
                Our love of glass and design started in Southern California with
                the iconic, modernist architecture of Neutra, Schindler and
                Koenig. As our state became more environmentally conscious, so
                did we. And our passion for glass evolved into a commitment to
                energy efficiency and sustainable living throughout the
                Southwest.
              </p>
              <p>
                Now, glass is having another renaissance. Walls are being torn
                down, New windows and doors of possibilities are opening. And
                we’re committed to be a part of it. We’ve combined a glass
                fabrication company with our premium bifold and sliding door
                company to be a complete one-stop, single source for the best
                that glass has to offer.
              </p>
              <p>
                Calling all contractors, architects, glaziers, designers,
                artists and visionaries. Our fabrication awaits your
                imagination.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
