import React from "react";

import logo from "assets/images/header/logo.png";
import { Link } from "react-router-dom";

interface HomeNavigationProps {}

export const HomeNavigation: React.FC<HomeNavigationProps> = () => {
  return (
    <div className="home-navigation">
      <div className="home-navigation-container">
        <div className="home-navigation-logo">
          <div className="home-navigation-logo-image">
            <Link to="/">
              <img src={logo as any} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="home-navigation-links">
          <Link to="/glass">
            <div className="home-navigation-link">Our Glass</div>
          </Link>
          <Link to="/doors">
            <div className="home-navigation-link">Our Doors</div>
          </Link>
          <Link to="/about-us">
            <div className="home-navigation-link">Our Story</div>
          </Link>
          <Link to="/contact-us">
            <div className="home-navigation-link">Contact Us</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
