import { Footer } from "modules/Layout/Footer";
import { HomeNavigation } from "modules/Layout/Navigation";
import React from "react";
import { RouteContainer } from "RouteContainer";

function App() {
  return (
    <>
      <HomeNavigation />

      <div className="App">
        <RouteContainer />
        {/* <div
          className="footer-container"
          style={{
            width: "100%",
            backgroundColor: "#5c5e55",
            height: "500px",
          }}
        /> */}
        <Footer />
      </div>
    </>
  );
}

export default App;
