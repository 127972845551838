import React from "react";

interface ContactUsContainerProps {}

export const ContactUsContainer: React.FC<ContactUsContainerProps> = () => {
  return (
    <div className="contact-us-container">
      <div className="contact-us-content">
        <div className="contact-us-hero">
          <div className="contact-us-hero-left">
            <div className="contact-us-hero-left-text">
              <div>
                Whether you’re interested in a bifold or sliding door system, a
                work of glass or just want to throw around some ideas, we’ll
                happily put you in touch with one of our representatives.
              </div>
              <div className="contact-us-call-us">
                Email us at{" "}
                <span className="contact-us-phone-number">
                  stephen@ennion.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
