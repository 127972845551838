import React from "react";

interface DoorsContainerProps {}

export const DoorsContainer: React.FC<DoorsContainerProps> = () => {
  return (
    <div className="product-page-container">
      <div className="product-page-content-container">
        <div className="product-page-hero product-page-hero-doors">
          <div className="product-page-hero-left product-page-hero-left-doors">
            <div className="product-page-hero-left-text">
              Let’s face it. Any sliding door system lets you to bring the
              outside in. But at Ennion, our point of view is quite different
              from our competitors. We believe in giving you the same high-end
              features as the premium brands. Without the same high-end price
              tags.
            </div>
          </div>
        </div>
        <div className="product-page-split-section product-page-split-section-column-reverse">
          <div className="product-page-split-section-image doors-split-section-image-1" />
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              More expansive than expensive.
            </div>
            <div className="product-page-split-section-text-content">
              The way we see it, flexibility is essential in life and in sliding
              doors So, all our multi-slide systems are designed to open on both
              sides. With us, that’s a standard feature. Not an option that you
              have to pay extra for.
            </div>
          </div>
        </div>

        <div className="product-page-split-section">
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              all-aluminum. all the time.
            </div>
            <div className="product-page-split-section-text-content">
              All of our bi-folds, multi-slides and corner door systems come
              with aluminum frames to give you more glass, more structure and a
              much better slide. We make aluminum frames in white or black, and
              a number of custom laminate colors.
            </div>
          </div>
          <div className="product-page-split-section-image doors-split-section-image-2" />
        </div>

        <div className="product-page-split-section product-page-split-section-column-reverse">
          <div className="product-page-split-section-image doors-split-section-image-3" />
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              thermally broken. without breaking the bank.
            </div>
            <div className="product-page-split-section-text-content">
              Before we came along, you’d have to pay considerably more to get
              thermally broken aluminum. But we don’t think you should have to
              pay extra for better insulation and energy-efficiency. So, thermal
              breaks come standard with every one of our systems.
            </div>
          </div>
        </div>

        <div className="product-page-split-section">
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              bottom loading is a top priority.
            </div>
            <div className="product-page-split-section-text-content">
              Every door we make is bottom-loaded which makes them more stable,
              reliable and smooth. And the bottom line is, they’re also less
              expensive and easier to install than traditional top-hung
              engineering.
            </div>
          </div>
          <div className="product-page-split-section-image doors-split-section-image-4" />
        </div>

        <div className="product-page-bottom-section-container doors-bottom-section-container-bifold">
          <div className="product-page-bottom-section-text">
            <div className="product-page-bottom-section-text-title">
              Our Bi-fold Door System:
              <br />
              Let the advantages unfold.
            </div>
            <div className="product-page-bottom-section-text-content">
              With premium hardware, state-of-the-art engineering, and thermally
              broken aluminum, our Bi-fold competes with other door systems that
              are considerably more. Thanks to the bottom-hung design, it’s
              naturally balanced, And doesn’t require expensive retrofitting or
              reinforcement.
            </div>
          </div>
          <div className="product-page-bottom-section-image doors-bottom-section-image-bifold" />
        </div>

        <div className="product-page-bottom-section-container doors-bottom-section-container-multislide">
          <div className="product-page-bottom-section-text">
            <div className="product-page-bottom-section-text-title">
              Our Multi-slide Door System:
              <br />
              Engineered for flexibility.
              <br />
              And affordability.
            </div>
            <div className="product-page-bottom-section-text-content">
              The Helia Door is a stackable door system without fixed panels. In
              other words, homeowners are able to move the panels anywhere along
              the rail to adjust and adapt to their individual needs. It’s the
              only removable wall solution that offers this kind of quality and
              flexibility. At this kind of price.
            </div>
          </div>
          <div className="product-page-bottom-section-image doors-bottom-section-image-multislide" />
        </div>
      </div>
    </div>
  );
};
