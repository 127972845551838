import React from "react";

interface GlassContainerProps {}

export const GlassContainer: React.FC<GlassContainerProps> = () => {
  return (
    <div className="product-page-container">
      <div className="product-page-content-container">
        <div className="product-page-hero product-page-hero-glass">
          <div className="product-page-hero-left product-page-hero-left-glass">
            <div className="product-page-hero-left-text">
              As a specialty player with decades of experience in glass
              fabrication, we do it all. We temper it. We cut it. We drill it.
              We round it. We notch it. We paint it. We wire it. We polish it.
              We bevel it. We engrave it. We laminate it. You name it.
            </div>
          </div>
        </div>
        <div className="product-page-split-section product-page-split-section-column-reverse">
          <div className="product-page-split-section-image glass-split-section-image-1" />
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              Our capabilities are enormous. our prices are not.
            </div>
            <div className="product-page-split-section-text-content">
              We turn glass into staircases. Hand rails. Back splashes. Front
              doors. Canopies. Partitions. Furniture. Kitchen cabinets. Shower
              doors. (And that’s not including all the custom projects we dream
              up with our clients.) Whatever we make, we make sure it’s always
              the highest quality. But not the highest price.
            </div>
          </div>
        </div>

        <div className="product-page-split-section">
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              it’s more than a business. It’s an art form.
            </div>
            <div className="product-page-split-section-text-content">
              To be completely transparent, we’re obsessed with glass. How it
              invites more light in. And expands spaces. And improves
              ventilation. And frames the world like nothing else. So, if you’ve
              got a vision, we’ve got the experience, the skills, and the
              passion to make it a reality.
            </div>
          </div>
          <div className="product-page-split-section-image glass-split-section-image-2" />
        </div>

        <div className="product-page-split-section product-page-split-section-column-reverse">
          <div className="product-page-split-section-image glass-split-section-image-3" />
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              We’re big on the smallest details.
            </div>
            <div className="product-page-split-section-text-content">
              We’re as particular about the fit of a frameless shower door as
              you are. In fact, we’d venture to say that no one’s as picky as we
              are about finishes, textures and colors. Or offers you more to
              choose from. Or gets the job done in less time.
            </div>
          </div>
        </div>

        <div className="product-page-split-section">
          <div className="product-page-split-section-text">
            <div className="product-page-split-section-text-title">
              Clearly, we mean business.
            </div>
            <div className="product-page-split-section-text-content">
              While were very much at home doing residential projects, we’ve
              done tons of commercial work, too. From smart glass conference
              rooms to glass curtain museum installations. Our resume also
              includes airports, restaurants, hotel lobbies and shopping malls.
            </div>
          </div>
          <div className="product-page-split-section-image glass-split-section-image-4" />
        </div>

        {/* <div className="product-page-bottom-section-container doors-bottom-section-container-bifold">
          <div className="product-page-bottom-section-text">
            <div className="product-page-bottom-section-text-title">
              Let the possibilities unfold.
              <br />
              the bifold door system.
            </div>
            <div className="product-page-bottom-section-text-content">
              The Bifold Door System stands in a league of its own. With premium
              hardware, state-of-art engineering, and thermally broken aluminum,
              the Bifold competes with door systems 2 to 3 times the price. With
              its bottom hung design, the Bifold is naturally balanced and does
              not require expensive retrofitting or reinforcement.
            </div>
          </div>
          <div className="product-page-bottom-section-image doors-bottom-section-image-bifold" />
        </div>

        <div className="product-page-bottom-section-container doors-bottom-section-container-multislide">
          <div className="product-page-bottom-section-text">
            <div className="product-page-bottom-section-text-title">
              Engineered for flexibilty. <br />
              And affordability. <br />
              the multislide door system.
            </div>
            <div className="product-page-bottom-section-text-content">
              The Multislide Door System Introducing our third generation of
              sliding door technology and design, the Helia Door, a stackable
              door system without fixed panels. Panels can be stacked and moved
              anywhere along the rail giving homeowners a system that can adjust
              and adapt to your needs. The Helia Door is the only removable wall
              solution engineered for flexibility without compromising quality
              and affordability.
            </div>
          </div>
          <div className="product-page-bottom-section-image doors-bottom-section-image-multislide" />
        </div> */}
      </div>
    </div>
  );
};
