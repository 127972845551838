import React, { useEffect, useState } from "react";

import hero_tag_imagination from "assets/images/home/hero_tag_imagination.png";
import hero_tag_meets from "assets/images/home/hero_tag_meets.png";
import hero_tag_fabrication from "assets/images/home/hero_tag_fabrication.png";
import { Link } from "react-router-dom";

interface HomeContainerProps {}

export const HomeContainer: React.FC<HomeContainerProps> = () => {
  const [heroColored, setHeroColored] = useState(false);
  const [imaginationVisible, setImaginationVisible] = useState(false);
  const [meetsVisible, setMeetsVisible] = useState(false);
  const [fabricationVisible, setFabricationVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setHeroColored(true), 1000);
    setTimeout(() => setImaginationVisible(true), 1800);
    setTimeout(() => setMeetsVisible(true), 3000);
    setTimeout(() => setFabricationVisible(true), 4200);
  }, []);

  const heroColoredClass = heroColored
    ? "home-hero-colored home-hero-colored-visible"
    : "home-hero-colored";

  return (
    <div className="home-container">
      <div className="home-hero">
        <div className={heroColoredClass} />
        <div className="home-hero-content">
          <div className="home-hero-tag">
            {imaginationVisible && (
              <div className="home-hero-tag-imagination">
                <img src={hero_tag_imagination as any} alt="imagination" />
              </div>
            )}
            {meetsVisible && (
              <div className="home-hero-tag-meets">
                <img src={hero_tag_meets as any} alt="meets" />
              </div>
            )}
            {fabricationVisible && (
              <div className="home-hero-tag-fabrication">
                <img src={hero_tag_fabrication as any} alt="fabrication" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="home-info">
        If you can think it, we can make it. From a 3-story floating glass
        staircase to a 22-foot, multi-slide door system. And at ENNION, we don’t
        just work with glass, we work with people. We are passionate about
        bringing your plans to life. Professionally. Punctually. And for
        considerably less than you’d imagine.
      </div>
      <div className="home-lower-heroes">
        <Link to="/glass" className="home-glass-hero home-lower-hero">
          <div className="home-lower-hero-text">
            If you can picture it, we can perfect it.
          </div>
          <div className="home-lower-hero-link">Glass</div>
        </Link>
        <Link to="/doors" className="home-doors-hero home-lower-hero">
          <div className="home-lower-hero-text">
            If you can envision it, we can expand upon it.
          </div>
          <div className="home-lower-hero-link">Doors</div>
        </Link>
      </div>
    </div>
  );
};
