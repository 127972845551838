import React from "react";
import { Route, Switch } from "react-router-dom";

import { AboutUsContainer } from "modules/AboutUs/Container";
import { DoorsContainer } from "modules/Doors/Container";
import { GlassContainer } from "modules/Glass/Container";
import { HomeContainer } from "modules/Home/Container";
import { ContactUsContainer } from "modules/ContactUs/Container";

interface RouteContainerProps {}

export const RouteContainer: React.FC<RouteContainerProps> = () => {
  return (
    <Switch>
      <Route exact={true} path="/" render={() => <HomeContainer />} />
      <Route exact={true} path="/doors" render={() => <DoorsContainer />} />
      <Route exact={true} path="/glass" render={() => <GlassContainer />} />
      <Route
        exact={true}
        path="/about-us"
        render={() => <AboutUsContainer />}
      />
      <Route
        exact={true}
        path="/contact-us"
        render={() => <ContactUsContainer />}
      />
    </Switch>
  );
};
